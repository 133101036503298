// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEnvelope as faSolidEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faTags } from '@fortawesome/pro-light-svg-icons/faTags';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faHome as faSolidHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faCheck as faLightCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faBirthdayCake } from '@fortawesome/pro-light-svg-icons/faBirthdayCake';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { faBolt as faSolidBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSearch as faSolidSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheck as faSolidCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faExclamationTriangle as faSolidExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faHatChef } from '@fortawesome/pro-solid-svg-icons/faHatChef';
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faList as faSolidList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faTags as faSolidTags } from '@fortawesome/pro-solid-svg-icons/faTags';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCalendarAlt as faCalendarAltLight } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUser as faUserLight } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faFilm } from '@fortawesome/pro-regular-svg-icons/faFilm';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faClock as faClockLight } from '@fortawesome/pro-light-svg-icons/faClock';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';

// CSS is added already of server side, so we don't need to re-add it here.
window.FontAwesomeConfig.autoAddCss = false;

export opaque type Icon = mixed;
export type FontAwesomeComponent = React.AbstractComponent<{|
  className?: string,
  icon: Icon,
  spin?: boolean
|}>;

const TypedFontAwesomeIcon: FontAwesomeComponent = FontAwesomeIcon;

export default TypedFontAwesomeIcon;

export const envelope: Icon = faEnvelope;
export const solidEnvelope: Icon = faSolidEnvelope;
export const angleLeft: Icon = faAngleLeft;
export const caretDown: Icon = faCaretDown;
export const caretUp: Icon = faCaretUp;
export const tags: Icon = faTags;
export const tag: Icon = faTag;
export const timesCircle: Icon = faTimesCircle;
export const times: Icon = faTimes;
export const home: Icon = faHome;
export const solidHome: Icon = faSolidHome;
export const briefcase: Icon = faBriefcase;
export const birthdayCake: Icon = faBirthdayCake;
export const star: Icon = faStar;
export const solidStar: Icon = faSolidStar;
export const handshake: Icon = faHandshake;
export const piggyBank: Icon = faPiggyBank;
export const bolt: Icon = faBolt;
export const solidBolt: Icon = faSolidBolt;
export const search: Icon = faSearch;
export const solidSearch: Icon = faSolidSearch;
export const check: Icon = faCheck;
export const solidCheck: Icon = faSolidCheck;
export const lightCheck: Icon = faLightCheck;
export const spinner: Icon = faSpinner;
export const exclamationTriangle: Icon = faExclamationTriangle;
export const solidExclamationTriangle: Icon = faSolidExclamationTriangle;
export const hatChef: Icon = faHatChef;
export const utensils: Icon = faUtensils;
export const list: Icon = faList;
export const solidList: Icon = faSolidList;
export const solidTags: Icon = faSolidTags;
export const checkSquare: Icon = faCheckSquare;
export const dollarSign: Icon = faDollarSign;
export const calendarAlt: Icon = faCalendarAlt;
export const calendarAltLight: Icon = faCalendarAltLight;
export const mapMarker: Icon = faMapMarker;
export const user: Icon = faUser;
export const userLight: Icon = faUserLight;
export const userFriends: Icon = faUserFriends;
export const plus: Icon = faPlus;
export const minus: Icon = faMinus;
export const externalLink: Icon = faExternalLink;
export const film: Icon = faFilm;
export const download: Icon = faDownload;
export const edit: Icon = faEdit;
export const clockLight: Icon = faClockLight;
export const coins: Icon = faCoins;
export const phone: Icon = faPhone;
export const trash: Icon = faTrashAlt;
