// @flow
/*
 * Venuu-branded Checkbox element with larger touch area.
 *
 * Example usage:
 *
 * <Checkbox
 *   name="Some option name"
 *   inputProps={{
 *     name: 'catering[option_name]',
 *     onChange: (event) => { alert(event.target.value) }
 *   }}
 * />
 *
 */

import * as React from 'react';

import cx from 'classnames';

import FontAwesomeIcon, { check } from 'common/components/FontAwesomeIcon';
import styles from './Checkbox.scss';

type InputProps = { [key: string]: any, ... };

type Props = {|
  children: React.Node,
  inputProps?: InputProps
|};
export function CheckboxWithChildren({
  children,
  inputProps
}: Props): React.Node {
  return (
    <label className={styles.label}>
      <input {...inputProps} type="checkbox" />
      <FontAwesomeIcon className={styles.icon} icon={check} />
      <div className={styles.labelText}>{children}</div>
    </label>
  );
}

type PropsAlt = {|
  name: string,
  verticalAlign?: 'default' | 'middle',
  inputProps?: InputProps
|};
export default function Checkbox({
  name,
  verticalAlign = 'default',
  inputProps
}: PropsAlt): React.Node {
  return (
    <label
      className={cx({
        [styles.label]: true,
        [styles.verticalAlignMiddle]: verticalAlign === 'middle'
      })}
    >
      <input {...inputProps} type="checkbox" />
      <FontAwesomeIcon
        className={cx({
          [styles.icon]: true,
          [styles.verticalAlignMiddle]: verticalAlign === 'middle'
        })}
        icon={check}
      />
      <span className={styles.labelText}>{name}</span>
    </label>
  );
}
